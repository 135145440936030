import { combineReducers } from 'redux';
import errorReducer from './error-reducer';
import locationsReducer from './location-reducer';
import ratingsReviewsReducer from './ratings-reviews-reducer';
import rtaDataReducer from './rta-reducer';
import pcrDataReducer from './pcr-reducer';
import dncDataReducer from './dnc-reducer';
import qasReducer from './qas-reducer';
import brochureReducer from './brochure-reducer';
import rtaLogReducer from './rta-log-reducer';
import ecommReducer from './ecomm-reducer';
import projectsReducer from './projects-reducer';
import privateRoutesReducer from './private-routes-reducer';
export default combineReducers({
	error: errorReducer,
	locations: locationsReducer,
	reviews: ratingsReviewsReducer,
	rtaData: rtaDataReducer,
	pcrData: pcrDataReducer,
	dncData: dncDataReducer,
	qasData: qasReducer,
	brochureData: brochureReducer,
	rtaLogData: rtaLogReducer,
	ecommData: ecommReducer,
	projectData: projectsReducer,
	signInState: privateRoutesReducer
});
