import { graphql, useStaticQuery } from 'gatsby';

export const useBranchData = () => {
	const data = useStaticQuery(graphql`
		query {
			allContentstackBranchData {
				nodes {
					branch_retail
					additional_showrooms
					tag_name
					state {
						state_name
						abbreviation
						canada
						province
					}
					by_state
					state_url
					formal_name
					branch_url
					phone_number
					local_nav {
						...CTA
					}
					branch_disclaimer
					ratings_id
					hide_promo
					hide_rta
					hide_financing
					financing_options {
						embed_option {
							description
							terms_and_conditions
						}
						manual_option {
							manual_type
							file {
								url
								gatsbyImageData(quality: 90)
							}
							heading
							subheading
							cta {
								...CTA
							}
						}
					}
					financing_error
				}
			}
		}
	`);

	return data.allContentstackBranchData.nodes;
};
