import { GET_APPOINTMENTS, RESCHEDULE, CANCEL, STORE_APPOINTMENT_CHANGE, SUBMIT_APPOINTMENT_CHANGE_ERROR, RESET_PCR_DATA } from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_APPOINTMENTS:
			return {
				...state,
				...action.payload,
			};
		case RESCHEDULE:
			return {
				...state,
				...action.payload,
			};
		case CANCEL:
			return {
				...state,
				...action.payload,
			};
		case STORE_APPOINTMENT_CHANGE:
			return {
				...state,
				...action.payload,
			};
		case SUBMIT_APPOINTMENT_CHANGE_ERROR:
			return {
				...state,
				...action.payload,
			};
		case RESET_PCR_DATA:
			return initialState;
		default:
			return state;
	}
}
