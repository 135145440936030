import { GET_PDSN_RATING, GET_PDSN_REVIEWS, GET_PRODUCT_RATING, GET_PRODUCT_REVIEWS, GET_ALL_PRODUCT_RATINGS, RESET_REVIEWS } from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case GET_PDSN_RATING:
			return action.payload;
		case GET_PRODUCT_RATING:
			return action.payload;
		case GET_PDSN_REVIEWS:
			return action.payload;
		case GET_PRODUCT_REVIEWS:
			return action.payload;
		case GET_ALL_PRODUCT_RATINGS:
			return action.payload;
		case RESET_REVIEWS:
			return {};
		default:
			return state;
	}
}
