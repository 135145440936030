import { SIGN_IN_STATE, RESET_SIGN_IN_STATE, SIGN_IN_CHECK, SET_NAME, SET_CART } from '../actions/types';

const initialState = {
	isLogged: false,
	firstname: '',
	email: '',
	customerType: 'homeowner',
	postalCode: '',
	error: {},
	cart: {},
	cartFetched: false,
	checked: false, // Check on session begin
};
// Expected State for Signed In
// {
// 	isLogged: true,
// 	firstname: 'first name of user',
//	checked: true
// }

export default function (state = initialState, action) {
	switch (action.type) {
		case SIGN_IN_STATE:
			return {
				...state,
				isLogged: true,
				firstname: action.payload.firstname,
				email: action.payload.email,
				customerType: action.payload.CustomerType,
				postalCode: action.payload.PostalCode === 'no postal code' ? '' : action.payload.PostalCode,
				error: action.payload.error,
			};
		case SIGN_IN_CHECK:
			return {
				...state,
				checked: true,
			};
		case SET_NAME:
			return {
				...state,
				firstname: action.payload,
			};
		case SET_CART:
			return {
				...state,
				cart: action.payload,
				cartFetched: true,
			};
		case RESET_SIGN_IN_STATE:
			return {
				...initialState,
				checked: true,
			};
		default:
			return state;
	}
}
