import { graphql, useStaticQuery } from 'gatsby';

export const useShowroomUrls = () => {
	const data = useStaticQuery(graphql`
		query {
			newShowrooms: allContentstackBranchShowroom {
				nodes {
					url
					showroom_id
					page_heading
				}
			}
			oldShowrooms: allContentstackShowroom {
				nodes {
					url
					showroom_id
				}
			}
		}
	`);

	return data;
};
