import { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { getSessionStorage, setSessionStorage, formatPhoneNumber } from '../../utilities/functions';
import { generateUTMParams } from '../../page/rta/rta-common/scripts';
import { useProduct } from '../../../hooks/use-product';

const PelCampaignCode = ({ children }) => {
	const { fetchProductTiles } = useProduct();
	const dispatch = useDispatch();
	const codes = useStaticQuery(query)
		?.contentstackCampaignCodes?.cluster?.map((pair) => pair.pairs)
		?.flat();

	useEffect(() => {
		if (!getSessionStorage('activeSession')) {
			dispatch(fetchProductTiles());
			const queryParams = queryString.parse(window.location.search);
			generateUTMParams(queryParams);
			setSessionStorage('activeSession', 'true');
		}
	}, [dispatch, fetchProductTiles]);

	useEffect(() => {
		// Call Tracking - Replace phone numbers on RTA and in local nav
		let count = 0;
		let params = new URLSearchParams(window.location.search);
		let campaign = params.get('pel_campaign_code') || getSessionStorage('pel_campaign_code');

		if (campaign != null && codes?.find((code) => code.campaign_code === campaign)?.tfn) {
			let interval = setInterval(() => {
				count += 1;

				if (count === 5 || document.querySelector('.localNavTop')) clearInterval(interval);

				const campaignPhone = codes?.find((code) => code.campaign_code === campaign)?.tfn;
				const phoneArr = [...document.querySelectorAll('.localNavTop a[href^="tel:"]'), ...document.querySelectorAll('.rta-description-copy a[href^="tel:"]'), ...document.querySelectorAll('.ctPhone')];
				if (phoneArr?.[0]) {
					phoneArr.forEach((link) => {
						link.href = `tel:${campaignPhone}`;
						link.innerText = formatPhoneNumber(campaignPhone);
					});
				}
			}, 1000);
		}
	}, [codes]);

	return children;
};

export default PelCampaignCode;

export const query = graphql`
	{
		contentstackCampaignCodes {
			cluster {
				pairs {
					campaign_code
					tfn
				}
			}
		}
	}
`;
