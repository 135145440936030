import { CVV_REGEX, MM_YY_REGEX, NON_DIGIT_REGEX } from '../../constants/regex';
import { caZipRegex, emailRegex, nameRegex, phoneRegex, usZipRegex } from './constants';

/**
 * Validation functions.
 * @module Validators
 */

/**
 * Validates a postal code.
 * @param {string} [value=''] - The value to be validated.
 * @returns {boolean} - True if the value exists and is a valid postal code, otherwise false.
 */
export const isValidPostal = (value = '') => {
	if (typeof value !== 'string') {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('isValidPostal - Invalid Postal Code type: value must be a string');
	}
	return value && typeof value === 'string' && (usZipRegex.test(value) || caZipRegex.test(value)) ? true : false;
};

/**
 * Validates an email address.
 * @param {string} [value=''] - The value to be validated.
 * @returns {boolean} - True if the value exists and is a valid email address, otherwise false.
 */
export const isValidEmail = (value = '') => {
	if (typeof value !== 'string') {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('isValidEmail - Invalid Email type: value must be a string');
	}
	return value && typeof value === 'string' && emailRegex.test(value) ? true : false;
};

/**
 * Validates a name.
 * @param {string} [value=''] - The value to be validated.
 * @returns {boolean} - True if the value exists and is a valid name, otherwise false.
 */
export const isValidName = (value = '') => {
	if (typeof value !== 'string') {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('isValidName - Invalid Name type: value must be a string');
	}
	return value && typeof value === 'string' && nameRegex.test(value) ? true : false;
};

/**
 * Validates a phone number.
 * @param {string} [value=''] - The value to be validated.
 * @returns {boolean} - True if the value exists and is a valid phone number, otherwise false.
 */
export const isValidPhoneNumber = (value = '') => {
	if (typeof value !== 'string') {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('isValidPhoneNumber - Invalid Phone type: value must be a string');
	}
	return value && typeof value === 'string' && phoneRegex.test(value) ? true : false;
};

/**
 * Validates an address is complete.
 * @param {object} [value={}] - The value to be validated.
 * @returns {boolean} - True if the value exists and all required values exist, otherwise false.
 */
export const isCompleteAddress = (value = {}) => {
	if (!isValidObject(value)) {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('isCompleteAddress - Invalid Address type: value must be an object');
	}
	const { address1, city, state, postalCode } = value;

	return address1 && city && state && isValidPostal(postalCode) ? true : false;
};

/**
 * Validates if a given value is an array and has at least one element.
 * @param {array} value - The value to be validated.
 * @returns {boolean} - True if the value exists and is a non-empty array, otherwise false.
 */
export const isValidArray = (value) => (value && Array.isArray(value) && value.length > 0 ? true : false);

/**
 * Validates whether a given value exists and is an object.
 * @param {object} value - The value to be validated.
 * @returns {boolean} - True if the value exists and is a non-null object, otherwise false.
 */
export const isValidObject = (value) => (value && value != null && typeof value === 'object' ? true : false);

/**
 * Validates whether a credit card number is valid.
 * @param {string} value - The value to be validated.
 * @returns {boolean} - True if the value exists, 13-19 digits long and Luhn algorithm validates, otherwise false.
 */
export const isValidCCNumber = (value = '') => {
	if (typeof value !== 'string') {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('isValidCCNumber - Invalid Credit Card Number type: value must be a string');
	}

	// Remove any non-digit characters from the input
	const cleanedCardNumber = value.replace(NON_DIGIT_REGEX, '');

	// Check if the input is a numeric value and has a length between 13 and 19 digits
	if (!/^\d{13,19}$/.test(cleanedCardNumber)) {
		return false;
	}

	// Apply the Luhn algorithm
	let sum = 0;
	let double = false;

	for (let i = cleanedCardNumber.length - 1; i >= 0; i--) {
		let digit = parseInt(cleanedCardNumber.charAt(i), 10);

		if (double) {
			digit *= 2;
			if (digit > 9) {
				digit -= 9;
			}
		}

		sum += digit;
		double = !double;
	}

	return sum % 10 === 0;
};

export const isValidCVV = (value = {}) => {
	if (!isValidObject(value)) {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('isValidCVV - Invalid CVV type: value must be an object');
	}
	const { cvv, cardType } = value;
	const cvvRegex = cardType === 'american express' ? CVV_REGEX[1] : CVV_REGEX[0];
	return cvvRegex.test(cvv);
};

export const isValidExpirationDate = (value = '') => {
	if (typeof value !== 'string') {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('isValidExpirationDate - Invalid Expiration Date type: value must be a string');
	}

	const match = value.match(MM_YY_REGEX);
	if (!match) {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('Invalid expiration date format. Expected MM/YY');
		return false;
	}

	const inputMonth = parseInt(match[1], 10);
	const inputYear = parseInt(match[2], 10);
	const currentDate = new Date();
	const currentMonth = currentDate.getMonth() + 1;
	const currentYear = currentDate.getFullYear() % 100;

	if (inputMonth < 1 || inputMonth > 12 || inputYear < currentYear || inputYear > currentYear + 10) {
		return false;
	}

	return inputYear > currentYear || (inputYear === currentYear && inputMonth >= currentMonth);
};
