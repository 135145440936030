export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// Location service
export const GET_BRANCH = 'GET_BRANCH';
export const GET_SHOWROOMS_BY_IP = 'GET_SHOWROOMS_BY_IP';
export const GET_SHOWROOMS = 'GET_SHOWROOMS';
export const GET_SHOWROOM_BY_IP = 'GET_SHOWROOM_BY_IP';
export const GET_SHOWROOM = 'GET_SHOWROOM';
export const GET_SHOWROOM_BY_ID = 'GET_SHOWROOM_BY_ID';
export const GET_LOWES_STORES_BY_IP = 'GET_LOWES_STORES_BY_IP';
export const GET_LOWES_STORES = 'GET_LOWES_STORES';
export const GET_LOWES_STORE_BY_IP = 'GET_LOWES_STORE_BY_IP';
export const GET_LOWES_STORE = 'GET_LOWES_STORE';
export const GET_CONTRACTORS = 'GET_CONTRACTORS';
export const GET_STORES = 'GET_STORES';
export const GET_MARKET_BY_IP = 'GET_MARKET_BY_IP';
export const GET_MARKET = 'GET_MARKET';
export const GET_SERVICE_INFO = 'GET_SERVICE_INFO';
export const GET_BETA = 'GET_BETA';
export const RESET_SHOWROOMS = 'RESET_SHOWROOMS';
export const RESET_LOWES = 'RESET_LOWES';
export const RESET_CONTRACTORS = 'RESET_CONTRACTORS';
export const RESET_STORES = 'RESET_STORES';
export const RESET_MARKET = 'RESET_MARKET';
export const RESET_LOCATION_SERVICE = 'RESET_LOCATION_SERVICE';

// Ratings and Reviews
export const GET_PDSN_RATING = 'GET_PDSN_RATING';
export const GET_PDSN_REVIEWS = 'GET_PDSN_REVIEWS';
export const GET_PRODUCT_RATING = 'GET_PRODUCT_RATING';
export const GET_PRODUCT_REVIEWS = 'GET_PRODUCT_REVIEWS';
export const GET_ALL_PRODUCT_RATINGS = 'GET_ALL_PRODUCT_RATINGS';
export const RESET_REVIEWS = 'RESET_REVIEWS';

// Territory
export const GET_ZIPCODE_TERRITORIES = 'GET_ZIPCODE_TERRITORIES';

// RTA
export const INSERT_PARTIAL_LEAD = 'INSERT_PARTIAL_LEAD';
export const NEXT_ID = 'NEXT_ID';
export const GET_CALENDAR_INFO = 'GET_CALENDAR_INFO';
export const APPOINTMENT = 'APPOINTMENT';
export const CALL_CENTER_APPOINTMENT = 'CALL_CENTER_APPOINTMENT';
export const TIMEBLOCK = 'TIMEBLOCK';
export const CONVERT_LEAD = 'CONVERT_LEAD';
export const STORE_APPOINTMENT = 'STORE_APPOINTMENT';
export const STORE_LEAD_CONVERT = 'STORE_LEAD_CONVERT';
export const STORE_APPOINTMENT_CHANGE = 'STORE_APPOINTMENT_CHANGE';
export const SUBMIT_APPOINTMENT_ERROR = 'SUBMIT_APPOINTMENT_ERROR';
export const SUBMIT_APPOINTMENT_CHANGE_ERROR = 'SUBMIT_APPOINTMENT_CHANGE_ERROR';
export const RESET_RTA_DATA = 'RESET_RTA_DATA';

// PCR
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const RESCHEDULE = 'RESCHEDULE';
export const CANCEL = 'CANCEL';
export const RESET_PCR_DATA = 'RESET_PCR_DATA';
export const CONFIRM_APPOINTMENTS = 'CONFIRM_APPOINTMENTS';

// DNC
export const DO_NOT_CONTACT = 'DO_NOT_CONTACT';
export const ADD_DNC_COMMENTS = 'ADD_COMMENTS';
export const RESET_DNC_DATA = 'RESET_DNC_DATA';

// QAS Address Validation
export const QAS_SEARCH = 'QAS_SEARCH';
export const QAS_FORMAT = 'QAS_FORMAT';
export const QAS_RESET = 'QAS_RESET';

//brochures
export const BROCHURES_PAGE = 'BROCHURES_PAGE';
export const CLEAR_BROCHUREDATA = 'CLEAR_BROCHUREDATA';

// RTA logging
export const RTA_LOG = 'RTA_LOG';

// Ecommerce
export const GET_API_AUTH_TOKEN = 'GET_API_AUTH_TOKEN';
export const CLEAR_ECOMM_ERRORS = 'CLEAR_ECOMM_ERRORS';
export const GET_TILES = 'GET_TILES';

// Projects/Quotes
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const CREATE_GROUP = 'CREATE_GROUP';
export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUP = 'GET_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const CREATE_ITEM = 'CREATE_ITEM';
export const GET_ITEMS = 'GET_ITEMS';
export const GET_ITEM = 'GET_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const RESET_PROJECTS = 'RESET_PROJECTS';
export const RESET_GROUPS = 'RESET_GROUPS';
export const RESET_ITEMS = 'RESET_ITEMS';
export const RESET_PROJECTS_GROUPS_ITEMS = 'RESET_PROJECTS_GROUPS_ITEMS';

// SignIn State
export const SIGN_IN_STATE = 'SIGN_IN_STATE';
export const RESET_SIGN_IN_STATE = 'RESET_SIGN_IN_STATE';
export const SIGN_IN_CHECK = 'SIGN_IN_CHECK';
export const SET_NAME = 'SET_NAME';
export const CUSTOMER_TYPE = 'CUSTOMER_TYPE';
export const SET_CART = 'SET_CART';
