import React from 'react';
import { Script } from 'gatsby';

export const Eloqua = () => (
	<Script
		id='eloqua-script'
		key='eloqua'
	>
		{`
			var _elqQ = _elqQ || [];
			_elqQ.push([ 'elqSetSiteId', '1920192983' ]);
			_elqQ.push([ 'elqUseFirstPartyCookie', 'tracking.pella.com' ]);
			_elqQ.push([ 'elqTrackPageView', window.location.href ]);

			var s = document.createElement('script');
			s.async = true;
			s.src = 'https://img03.en25.com/i/elqCfg.min.js';
			document.head.appendChild(s);

			function setFormFieldValue(elqForm, strFormFieldName, strContactFieldName){
				if (elqForm.elements[strFormFieldName])
					elqForm.elements[strFormFieldName].value = GetElqContentPersonalizationValue(strContactFieldName);
			}

			function SetElqContent() {
				if (this.GetElqContentPersonalizationValue && GetElqContentPersonalizationValue("V_Email_Address")) {
					_elqQ.push(['elqDataLookup', escape("6fdc24db-5b3a-494a-bf83-52e22d8fa2b0"), '<C_EmailAddress>' + GetElqContentPersonalizationValue('V_Email_Address') + '</C_EmailAddress>']);
					_elqQ.push(['elqDataLookup', escape("03741c19-2d84-48f9-8fea-4f91b5bb4a83"),'<C_EmailAddress>' + GetElqContentPersonalizationValue('V_Email_Address') + '</C_EmailAddress>']);
				} else if (this.GetElqContentPersonalizationValue && GetElqContentPersonalizationValue("C_EmailAddress")) {
					const data = {
						CustomerID: GetElqContentPersonalizationValue('ContactIDExt'),
						LeadScore: GetElqContentPersonalizationValue('C_Lead_Score'),
						LeadScoreImplicit: GetElqContentPersonalizationValue('C_Lead_Score___Implicit1'),
						LeadScoreExplicit: GetElqContentPersonalizationValue('C_Lead_Score___Explicit1'),
						LeadScoreMostRecent: GetElqContentPersonalizationValue('C_Lead_Score_Date___Most_Recent1'),
						LeadScoreDateProfile: GetElqContentPersonalizationValue('C_Lead_Score_Date___Profile___Most_Recent1'),
						LeadSourceMostRecent: GetElqContentPersonalizationValue('C_Lead_Source___Most_Recent1'),
						LeadSource: GetElqContentPersonalizationValue('C_Lead_Source___Original1'),
						DateCreated: GetElqContentPersonalizationValue('C_DateCreated'),
						DoNotEmail: GetElqContentPersonalizationValue('C_Do_Not_Email1'),
						DoNotText: GetElqContentPersonalizationValue('C_Do_Not_Text1'),
						Zip: GetElqContentPersonalizationValue('C_Zip_Postal'),
						Birthdate: GetElqContentPersonalizationValue('C_Birthdate1'),
						Company: GetElqContentPersonalizationValue('C_Company'),
						ContactPermissionGranted: GetElqContentPersonalizationValue('C_Contact_Permission_Granted1'),
						ContactPermissionRemoved: GetElqContentPersonalizationValue('C_Contact_Permission_Removed1'),
						BusinessEntity: GetElqContentPersonalizationValue('C_Business_Entity1'),
						BusinessEntitySubtype: GetElqContentPersonalizationValue('C_Business_Entity_Subtype1')
					};

					var elqForm;
					var blnFound = false;

					var prefData = {
						C_FirstName: GetElqContentPersonalizationValue('C_FirstName'),
						C_LastName: GetElqContentPersonalizationValue('C_LastName'),
						C_EmailAddress: GetElqContentPersonalizationValue('C_EmailAddress'),
						C_Zip_Postal: GetElqContentPersonalizationValue('C_Zip_Postal'),
						emailNewsletter: GetElqContentPersonalizationValue('C_Do_Not_Email___Newsletter1'),
						emailPromo: GetElqContentPersonalizationValue('C_Do_Not_Email___Promotional1'),
					};

					//find the form object from the form HTMLName
					for (var formIndex = 0; formIndex < document.forms.length; formIndex++)
					{
						for (var elemIndex = 0; elemIndex < document.forms[formIndex].length; elemIndex++)
						{
							if (document.forms[formIndex].elements[elemIndex].name == "elqFormName")
							{
								if (document.forms[formIndex].elements[elemIndex].value == "preference-center")
								{
									elqForm = document.forms[formIndex];
									blnFound = true;
									break;
								}
							}
						}
						if (blnFound) break;
					}

					if (elqForm)
					{
						setFormFieldValue(elqForm, "firstName", "C_FirstName");
						setFormFieldValue(elqForm, "lastName", "C_LastName");
						setFormFieldValue(elqForm, "emailAddress", "C_EmailAddress");
						setFormFieldValue(elqForm, "zipPostal", "C_Zip_Postal");
						// setFormFieldValue(elqForm, "emailNewsletter", "C_Do_Not_Email___Newsletter1");
						// setFormFieldValue(elqForm, "emailPromo", "C_Do_Not_Email___Promotional1");

						if (GetElqContentPersonalizationValue("C_Do_Not_Email___Newsletter1")) {
							if(GetElqContentPersonalizationValue("C_Do_Not_Email___Newsletter1") === '1') {
								elqForm.elements["emailNewsletter"].value = true;
								elqForm.elements["emailNewsletter"].checked = true;
							} else {
								elqForm.elements["emailNewsletter"].value = false;
								elqForm.elements["emailNewsletter"].checked = false;
							}

						}
						if (GetElqContentPersonalizationValue("C_Do_Not_Email___Promotional1")) {
							if(GetElqContentPersonalizationValue("C_Do_Not_Email___Promotional1") === '1') {
								elqForm.elements["emailPromo"].value = true;
								elqForm.elements["emailPromo"].checked = true;
							} else {
								elqForm.elements["emailPromo"].value = false;
								elqForm.elements["emailPromo"].checked = false;
							}
						}
					}

					// Push to global variable
					window.eloquaPrefData = window.eloquaPrefData || {};
					window.eloquaPrefData = prefData;
				}
			}

			_elqQ.push(['elqDataLookup', escape("67e165b8-93e7-4d84-aec5-0bfc26c38b6f"), '']);
		`}
	</Script>
);
