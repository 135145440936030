import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { getLocalStorage } from '../components/utilities/functions';

const CartContext = createContext({});
export default CartContext;

export const CartProvider = ({ children }) => {
	const [cart, setCart] = useState({});

	useEffect(() => {
		const cartSession = getLocalStorage('cartSession');
		if (cartSession) {
			setCart(cartSession);
		} else {
			setCart({});
		}
	}, []);

	return (
		<CartContext.Provider
			value={{
				cart,
				setCart,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

CartProvider.propTypes = {
	children: PropTypes.node,
};
