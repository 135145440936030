import { useContext } from 'react';
import OktaContext from '../context/OktaContext';

export const useOktaAuth = () => {
	const oktaAuth = useContext(OktaContext);

	if (oktaAuth === undefined) {
		throw new Error('useOktaAuth must be used within a Okta context provider');
	}

	return oktaAuth;
};
