export const SetAuthHeader = (accessToken = null, token = null) => {
	if (token) {
		return {
			Authorization: `Bearer ${token}`,
		};
	} else if (accessToken) {
		return {
			Authorization: `Bearer ${accessToken}`,
		};
	}

	return null;
};
