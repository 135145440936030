import React, { useState, useEffect } from 'react';
import { Script } from 'gatsby';

export const QuiqChat = () => {
	const [loaded, setLoaded] = useState(false);
	const [showChat, setShowChat] = useState(false);

	useEffect(() => {
		const date = new Date();
		const hour = date.getHours();
		const day = date.getDay();

		if (day > 0 && day < 6 && hour >= 8 && hour < 16) setShowChat(true);
	}, []);

	if (!showChat) return null;

	return (
		<>
			<Script
				id='quiq-chat-script'
				src={process.env.GATSBY_QUIQ_CHAT_URL}
				onLoad={() => setLoaded(true)}
				key='quiq-chat-script'
			/>

			{loaded && (
				<Script
					id='quiq-chat-script-2'
					key='quiq-chat-2'
				>
					{`
						if (typeof Quiq !== 'undefined') {
							var chat = Quiq({
								pageConfigurationId: 'support',
								seo: {
									delayScriptLoad: {
										enabled: true,
										delay: 5000
									}
								}
							});

							chat.on('statusChanged', async (event) => {
								if (event.data.status === 'initialized') {
									if (!chat.defaultWidget) return;

									const widgetState = await chat.defaultWidget.getState();
									if (widgetState?.currentPlatform?.name === 'SMS') {
										const realBtn = document.querySelector('.quiq-floating-element');
										const fauxBtn = document.createElement('button');

										fauxBtn.innerHTML = "<span class='sr-only'>Chat</span>";
										fauxBtn.setAttribute('style', 'position: absolute; left: 0; top: 0; width: 100%; height: 100%;');
										realBtn?.appendChild(fauxBtn);

										function onBubbleClick(e) {
											e.preventDefault();
											chat.showNotificationBlurb({
												text: 'Pella Support is ready to help. Please text "Help" when you enter your texting app on the next step.',
												buttons: [
													{
														text: 'Continue',
														action: {
															type: 'triggerDefaultAction'
														}
													}
												]
											});
											document.querySelector('.quiq-floating-element > button')?.remove();
										}

										fauxBtn.addEventListener('click', onBubbleClick);
									}
								}
							});
						}
					`}
				</Script>
			)}

			<style
				dangerouslySetInnerHTML={{
					__html: `
						.quiq-togglechatbutton-button:focus {
							outline: 2px solid #0e69e9;
						}
						.quiq-notificationblurb-wrapper {
							background-color: rgba(0, 0, 0, 0.9);
							border-radius: 0.5rem;
						}
						.quiq-notificationblurb-item-wrapper { margin: 0 !important; }
						.quiq-notificationblurb-content-children {
							background-color: transparent !important;
							color: #fff !important;
						}
						.quiq-notificationblurb-text-wrapper {
							font-size: 0.875rem !important;
							line-height: 1.3;
						}
						.quiq-notificationblurb-buttons-wrapper {
							margin: 0 auto 0 1rem !important;
							text-transform: uppercase !important;
							font-weight: 600 !important;
							letter-spacing: 1px !important;
						}
						.quiq-notificationblurb-button {
							background-color: #ffde00 !important;
							border-radius: 0 !important;
							margin: 0 0 1rem !important;
						}
						.quiq-notificationblurb-dismiss-button { 
							left: auto !important;
							right: -8px !important;
							top: -8px !important;
						}
					`,
				}}
			/>
		</>
	);
};
