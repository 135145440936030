import { CREATE_PROJECT, GET_PROJECTS, GET_PROJECT, UPDATE_PROJECT, DELETE_PROJECT, CREATE_GROUP, GET_GROUPS, GET_GROUP, UPDATE_GROUP, DELETE_GROUP, CREATE_ITEM, GET_ITEMS, GET_ITEM, UPDATE_ITEM, DELETE_ITEM, RESET_PROJECTS, RESET_GROUPS, RESET_ITEMS, RESET_PROJECTS_GROUPS_ITEMS } from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case CREATE_PROJECT:
			return {
				...state,
				...action.payload,
			};
		case GET_PROJECTS:
			return {
				...state,
				...action.payload,
			};
		case GET_PROJECT:
			return {
				...state,
				...action.payload,
			};
		case UPDATE_PROJECT:
			return {
				...state,
				...action.payload,
			};
		case DELETE_PROJECT:
			return {
				...state,
				Project: undefined,
			};
		case CREATE_GROUP:
			return {
				...state,
				...action.payload,
			};
		case GET_GROUPS:
			return {
				...state,
				...action.payload,
			};
		case GET_GROUP:
			return {
				...state,
				...action.payload,
			};
		case UPDATE_GROUP:
			return {
				...state,
				...action.payload,
			};
		case DELETE_GROUP:
			return {
				...state,
				Group: undefined,
			};
		case CREATE_ITEM:
			return {
				...state,
				Item: action.payload,
			};
		case GET_ITEMS:
			return {
				...state,
				...action.payload,
			};
		case GET_ITEM:
			return {
				...state,
				Item: action.payload,
			};
		case UPDATE_ITEM:
			return {
				...state,
				Item: action.payload,
			};
		case DELETE_ITEM:
			return {
				...state,
				Item: undefined,
			};
		case RESET_PROJECTS:
			return {
				...state,
				Project: undefined,
				Projects: undefined,
			};
		case RESET_GROUPS:
			return {
				...state,
				Group: undefined,
				Groups: undefined,
			};
		case RESET_ITEMS:
			return {
				...state,
				Item: undefined,
				Items: undefined,
			};
		case RESET_PROJECTS_GROUPS_ITEMS:
			return {};
		default:
			return state;
	}
}
