import { BROCHURES_PAGE, GET_ERRORS, CLEAR_BROCHUREDATA } from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
	switch (action.type) {
		case BROCHURES_PAGE:
			return {
				...state,
				...action.payload,
			};
		case CLEAR_BROCHUREDATA:
			return {
				initialState,
				...action.payload,
			};
		case GET_ERRORS:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
}
