import React from 'react';
import { Script } from 'gatsby';

export const CheqTag = () => (
	<>
		<Script
			id='cheq-script'
			key='cheq-script'
		>
			{`
				function onCheqResponse(arg1, requestId) {
					localStorage.setItem('cheqResponse', requestId);
				};

				(function(w,d,s){
					var scriptElement=d.getElementsByTagName(s)[0];
					var cheqScript=d.createElement(s);
					cheqScript.async=true;
					cheqScript.src='https://ob.sd6544.pella.com/i/0c2fc9e0dd87888c5b5c699373cd9566.js';
					cheqScript.setAttribute('data-ch','Pella');
					cheqScript.setAttribute('data-jsonp','onCheqResponse');
					cheqScript.className='ct_clicktrue_54936';
					scriptElement.parentNode.insertBefore(cheqScript,scriptElement);
				})(window,document,'script');
			`}
		</Script>

		<noscript>
        	<iframe
				title='Cheq'
				src='https://obs.sd6544.pella.com/ns/0c2fc9e0dd87888c5b5c699373cd9566.html?ch=Pella'
            	width='0'
				height='0'
				style={{ display: 'none', visibility: 'hidden' }}
			/>
        </noscript>
	</>
);
