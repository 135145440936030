import { GET_BRANCH, GET_SHOWROOMS_BY_IP, GET_SHOWROOMS, GET_SHOWROOM_BY_IP, GET_SHOWROOM, GET_SHOWROOM_BY_ID, GET_LOWES_STORES_BY_IP, GET_LOWES_STORES, GET_LOWES_STORE_BY_IP, GET_LOWES_STORE, GET_CONTRACTORS, GET_STORES, GET_MARKET_BY_IP, GET_MARKET, GET_SERVICE_INFO, GET_BETA, RESET_SHOWROOMS, RESET_LOWES, RESET_CONTRACTORS, RESET_STORES, RESET_MARKET, RESET_LOCATION_SERVICE } from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case GET_BRANCH:
			return {
				...state,
				zone: action.payload,
			};
		case GET_SHOWROOMS_BY_IP:
			return {
				...state,
				...action.payload,
			};
		case GET_SHOWROOMS:
			return {
				...state,
				...action.payload,
				event: action.event,
			};
		case GET_SHOWROOM_BY_IP:
			return {
				...state,
				showroom: action.payload,
			};
		case GET_SHOWROOM:
			return {
				...state,
				showroom: action.payload,
			};
		case GET_SHOWROOM_BY_ID:
			return {
				...state,
				showroom_info: action.payload,
			};
		case GET_LOWES_STORES_BY_IP:
			return {
				...state,
				...action.payload,
			};
		case GET_LOWES_STORES:
			return {
				...state,
				...action.payload,
			};
		case GET_LOWES_STORE_BY_IP:
			return {
				...state,
				lowes: action.payload,
			};
		case GET_LOWES_STORE:
			return {
				...state,
				lowes: action.payload,
			};
		case GET_CONTRACTORS:
			return {
				...state,
				...action.payload,
			};
		case GET_STORES:
			return {
				...state,
				...action.payload,
			};
		case GET_MARKET_BY_IP:
			return {
				...state,
				...action.payload,
				// beta: action.payload && action.payload.market ? true : false
			};
		case GET_MARKET:
			return {
				...state,
				...action.payload,
				// beta: action.payload && action.payload.market ? true : false
			};
		case GET_SERVICE_INFO:
			return {
				...state,
				service_info: action.payload,
			};
		case GET_BETA:
			return {
				...state,
				beta: action.payload,
			};
		case RESET_SHOWROOMS:
			return {
				...state,
				showroom: {},
				showrooms: [],
			};
		case RESET_LOWES:
			return {
				...state,
				lowes: {},
				lowes_stores: [],
			};
		case RESET_CONTRACTORS:
			return {
				...state,
				contractors: {},
			};
		case RESET_STORES:
			return {
				...state,
				stores: {},
			};
		case RESET_MARKET:
			state.market = null;
			if (typeof state.ecommerce === 'boolean') delete state.ecommerce;
			if (typeof state.delivery === 'boolean') delete state.delivery;
			if (typeof state.pickup === 'boolean') delete state.pickup;
			if (typeof state.parts_only === 'boolean') delete state.parts_only;
			return state;
		case RESET_LOCATION_SERVICE:
			return {};
		default:
			return state;
	}
}
