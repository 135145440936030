import { QAS_SEARCH, QAS_FORMAT, QAS_RESET } from '../actions/types';

const initalState = {};

export default function (state = initalState, action) {
	switch (action.type) {
		case QAS_SEARCH:
			return action.payload;
		case QAS_FORMAT:
			return action.payload;
		case QAS_RESET:
			return {};
		default:
			return state;
	}
}
