// RegExp
export const usZipRegex = /^\d{5}(?:-\d{4})?$/;

export const caZipRegex = /^(?!.*[DFIOQU])[A-VXY]\d[A-Z] ?-?\d[A-Z]\d$/i;

export const emailRegex = /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6}$/i;

export const nameRegex = /^[a-zA-Z' -]+$/;

export const phoneRegex = /^[+]?(\d{1,3})?[(]?(\d{3})[)]?[-\s.]?\d{3}[-\s.]?\d{4}$/;

export const phoneReplace = /\(|\)|\-|\_| /g;

export const amexRegex = /^(34|37)\d{13}$/;

export const visaRegex = /^4\d{15}$/;

export const mastercardRegex = /^5[1-5][0-9]{14}$/;

export const discoverRegex = /^6(?:011|5[0-9]{2})[0-9]{12}$/;

export const poBoxRegex = /\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box)\b/i;

// Generic constants
export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

// RTA common
export const RTA_COMMON = {
	INTERFACE: {
		WEB: 'Web',
		SHOWROOM: 'Showroom',
		CALL_CENTER: 'Call Center',
	},
	ZONE: {
		RT_ZONE: 'RT',
		NRT_ZONE: 'NRT',
	},
	TYPE: {
		HOME: 'home',
		VIRTUAL: 'virtual',
		PREFERENCE: 'preference',
		NRT: 'nrt',
		SHOWROOM: 'showroom',
	},
	BLOCK_TYPE: {
		BLOCK: 'Block',
		UNBLOCK: 'Unblock',
		UNBLOCK_BLOCK: 'Both',
	},
};

// RTA Errors
export const RTA_ERRORS = {
	RESOURCE_UNAVAILABLE: 'RESOURCE NOT AVAILABLE:',
	INVALID_POSTAL: 'Postal Code must be a valid Postal Code',
	INVALID_EMAIL: 'Email Address is not a valid format',
	INVALID_PHONE: 'Phone is not a valid phone number',
	POSTAL_REQUIRED: 'Postal Code is a required field',
	FIRSTNAME_REQUIRED: 'First Name is a required field',
	LASTNAME_REQUIRED: 'Last Name is a required field',
	EMAIL_REQUIRED: 'Email Address is a required field',
	PHONE_REQUIRED: 'Phone is a required field',
	PHONE_TYPE_REQUIRED: 'Phone Type is a required field',
	PRIVACY_REQUIRED: 'You must accept our Terms of Use and Privacy Policy to proceed',
	PREFERRED_DAY_REQUIRED: 'Preferred Day is a required field',
	PREFERRED_TIME_REQUIRED: 'Preferred Time is a required field',
	APPOINTMENT_TYPE_REQUIRED: 'Appointment Type is a required field',
	DATE_TIME_REQUIRED: 'Date and time is required',
	ADDRESS_VALIDATION_REQUIRED: 'Project Address must be validated',
	ADDRESS_LINE_1_REQUIRED: '',
	CITY_REQUIRED: '',
	STATE_REQUIRED: '',

	POSTAL_ERROR_HANDLER: `You must fill out <a href='#postalCode'>Postal Code</a>`,
	FIRSTNAME_ERROR_HANDLER: `You must fill out <a href='#firstname'>First Name</a>`,
	LASTNAME_ERROR_HANDLER: `You must fill out <a href='#lastname'>Last Name</a>`,
	EMAIL_ERROR_HANDLER: `You must provide a valid <a href='#email'>Email Address</a>`,
	PHONE_ERROR_HANDLER: `You must provide a <a href='#phone'>Phone Number</a>`,
	PHONE_TYPE_ERROR_HANDLER: `You must choose a <a href='#phonetype'>Phone Type</a>`,
	PRIVACY_ERROR_HANDLER: `You must accept our <a href='#privacyCheck'>Terms of Use and Privacy Policy</a> to proceed`,
	ADDRESS_VALIDATION_ERROR_HANDLER: 'Project Address must be validated',
	ADDRESS_LINE_1_ERROR_HANDLER: `You must fill out <a href='#address1'>Street Address</a>`,
	CITY_ERROR_HANDLER: `You must fill out <a href='#city'>City</a>`,
	STATE_ERROR_HANDLER: `You must fill out <a href='#state'>State</a>`,
	DATE_TIME_ERROR_HANDLER: `You must select a <a href='#js-Calendar'>Date and Time</a>`,
	PREFERRED_DAY_ERROR_HANDLER: `You must select a <a href='#preferenceDay'>Preferred Day</a>`,
	PREFERRED_TIME_ERROR_HANDLER: `You must select a <a href='#preferenceTime'>Preferred Time</a>`,
	APPOINTMENT_TYPE_ERROR_HANDLER: `You must choose an <a href='#appointmentType'>Appointment Type</a>`,
};

// Auth cookies
export const PELLA_JWT = 'PellaJwt';
export const PELLA_REFRESH_TOKEN = 'PellaRefreshToken';

//Lowes Locations for MapLinks (Where To Buy)
export const lowesLocations = [
	'01082',
	'01129',
	'01701',
	'01906',
	'02771',
	'02886',
	'02896',
	'03818',
	'04769',
	'04861',
	'06062',
	'06418',
	'06513',
	'07840',
	'08045',
	'08050',
	'08080',
	'08759',
	'10309',
	'10598',
	'10710',
	'11801',
	'12110',
	'12534',
	'13669',
	'14843',
	'14903',
	'15012',
	'15904',
	'16101',
	'16365',
	'16509',
	'17601',
	'18235',
	'18840',
	'19137',
	'19904',
	'19973',
	'20646',
	'20707',
	'20735',
	'20772',
	'21550',
	'21742',
	'22306',
	'22406',
	'22602',
	'23188',
	'23832',
	'24012',
	'24572',
	'24592',
	'24637',
	'26651',
	'27105',
	'27127',
	'27215',
	'27284',
	'27406',
	'27529',
	'27534',
	'27545',
	'27573',
	'28079',
	'28144',
	'28166',
	'28213',
	'28216',
	'28411',
	'28470',
	'28540',
	'28546',
	'28604',
	'28792',
	'29020',
	'29073',
	'29169',
	'29209',
	'29212',
	'29223',
	'29301',
	'29341',
	'29640',
	'29681',
	'29841',
	'30004',
	'30038',
	'30106',
	'30531',
	'30606',
	'31047',
	'31206',
	'31313',
	'31326',
	'31419',
	'31707',
	'31788',
	'31792',
	'32177',
	'32308',
	'33033',
	'35405',
	'35758',
	'36117',
	'37066',
	'37076',
	'37122',
	'37160',
	'37172',
	'37321',
	'37920',
	'37924',
	'38024',
	'38301',
	'38305',
	'38372',
	'38654',
	'40356',
	'40601',
	'40701',
	'41056',
	'41076',
	'41701',
	'42134',
	'42141',
	'42240',
	'42303',
	'43612',
	'44224',
	'44333',
	'44883',
	'45044',
	'45331',
	'45449',
	'45895',
	'46219',
	'46545',
	'46804',
	'47712',
	'47715',
	'48035',
	'48093',
	'48183',
	'48225',
	'48310',
	'48604',
	'49014',
	'49770',
	'50266',
	'53142',
	'54904',
	'55447',
	'58201',
	'60061',
	'60639',
	'60652',
	'60712',
	'61115',
	'61832',
	'62002',
	'62305',
	'62704',
	'63401',
	'63640',
	'64154',
	'64601',
	'65301',
	'65401',
	'67205',
	'71913',
	'72032',
	'72076',
	'72703',
	'72956',
	'73134',
	'74055',
	'74075',
	'74501',
	'74601',
	'74701',
	'75006',
	'75034',
	'75040',
	'75067',
	'75156',
	'75252',
	'75482',
	'75703',
	'76033',
	'76054',
	'76109',
	'76114',
	'76904',
	'77802',
	'77845',
	'78233',
	'78504',
	'78539',
	'78550',
	'78577',
	'78681',
	'79424',
	'79606',
	'79936',
	'80111',
	'80498',
	'80817',
	'83404',
	'83642',
	'83642',
	'84015',
	'84043',
	'84120',
	'85085',
	'85122',
	'85301',
	'86442',
	'87031',
	'87111',
	'89015',
	'89130',
	'89701',
	'90501',
	'90631',
	'90650',
	'91786',
	'91915',
	'92008',
	'92083',
	'92503',
	'93257',
	'93274',
	'93305',
	'93612',
	'94066',
	'94509',
	'94591',
	'95356',
	'95610',
	'95670',
	'97128',
	'97470',
	'98055',
	'98311',
	'98503',
	'98837',
	'98903',
	'99336',
];

// Pella Lat/Long
export const pellaLatitude = 41.3979968;
export const pellaLongitude = -92.9166324;

// Eloqua tracking url
export const eloquaTrackingUrl = 'https://tracking.pella.com/e/f2';
