import { GET_API_AUTH_TOKEN , GET_TILES } from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case GET_API_AUTH_TOKEN:
			return action.payload;
		case GET_TILES:
			return {
				...state,
				tiles: action.payload
			}
		default:
			return state;
	}
}
