import { INSERT_PARTIAL_LEAD, NEXT_ID, GET_CALENDAR_INFO, APPOINTMENT, CALL_CENTER_APPOINTMENT, TIMEBLOCK, CONVERT_LEAD, STORE_APPOINTMENT, STORE_LEAD_CONVERT, SUBMIT_APPOINTMENT_ERROR, RESET_RTA_DATA } from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
	switch (action.type) {
		case INSERT_PARTIAL_LEAD:
			return {
				...state,
				...action.payload,
			};
		case NEXT_ID:
			return {
				...state,
				...action.payload,
			};
		case GET_CALENDAR_INFO:
			return {
				...state,
				errorMessage: null,
				...action.payload,
			};
		case APPOINTMENT:
			return {
				...state,
				...action.payload,
			};
		case CALL_CENTER_APPOINTMENT:
			return {
				...state,
				...action.payload,
			};
		case TIMEBLOCK:
			return {
				...state,
				...action.payload,
			};
		case CONVERT_LEAD:
			return {
				...state,
				...action.payload,
			};
		case STORE_APPOINTMENT:
			return {
				...state,
				...action.payload,
			};
		case STORE_LEAD_CONVERT:
			return {
				...state,
				...action.payload,
			};
		case SUBMIT_APPOINTMENT_ERROR:
			return {
				...state,
				...action.payload,
			};
		case RESET_RTA_DATA:
			return {
				TerritoryGroup: null,
			};
		default:
			return state;
	}
}
