import React from 'react';
import { Provider } from 'react-redux';
import flagsmith from 'flagsmith/isomorphic';
import { FlagsmithProvider } from 'flagsmith/react';
import { Script } from 'gatsby';
import { OktaAuth, REFRESH_TOKEN_STORAGE_KEY } from '@okta/okta-auth-js';

import { setupStore } from './src/components/store';
import ErrorBoundaryComp from './src/components/utilities/error-boundary';
import { OktaProvider } from './src/context/OktaContext';
import { CartProvider } from './src/context/CartContext';
import { BranchProvider } from './src/context/BranchContext';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PelCampaignCode from './src/components/common/Pelcampaign-code';

import { Speedcurve } from './src/components/third-party/speedcurve';
import { Monetate } from './src/components/third-party/monetate';
import { Eloqua } from './src/components/third-party/eloqua';
import { QuiqChat } from './src/components/third-party/quiq-chat';
import { CheqTag } from './src/components/third-party/cheq';
import { GoogleTagManager } from './src/components/third-party/google-tagmanager';
import { removeLocalStorage } from './src/components/utilities/functions';

const oktaAuth = new OktaAuth({
	clientId: process.env.GATSBY_OKTA_CLIENTID,
	issuer: process.env.GATSBY_OKTA_DOMAIN,
	redirectUri: `/login/callback`,
	post_logout_redirect_uri: `/logout`,
	scopes: ['openid', 'profile', 'email', 'offline_access', 'ApiGw.account', 'ApiGw.web-project', 'ApiGw.web-product', 'ApiGw.web-customer', 'ApiGw.web-cart-order-mgmt'],
});

oktaAuth.tokenManager.on('expired', (key) => {
	if (key === REFRESH_TOKEN_STORAGE_KEY) {
		removeLocalStorage('authSession');
		removeLocalStorage('cartSession');
	}
});

oktaAuth.tokenManager.on('error', async (error) => {
	removeLocalStorage('authSession');
	removeLocalStorage('cartSession');

	try {
		await oktaAuth.revokeAccessToken();
		await oktaAuth.revokeRefreshToken();

		oktaAuth.tokenManager.clear();
	} catch {}
});

// eslint-disable-next-line react/display-name, react/prop-types -- fix this
export default ({ element }) => {
	const initialOptions = {
		'client-id': process.env.GATSBY_PAYPAL_CLIENT_ID,
		'currency': 'USD',
		'components': 'messages',
	};

	// Moved to init function to avoid re-render issues
	flagsmith.init({
		environmentID: process.env.GATSBY_FLAGSMITH_ENVIRONMENT_KEY,
		cacheFlags: true,
		cacheOptions: {
			ttl: 1000 * 60 * 5, // 5 minutes
			skipAPI: true,
		},
		enableAnalytics: true,
	});

	return (
		<Provider store={setupStore()}>
			<FlagsmithProvider flagsmith={flagsmith}>
				<OktaProvider oktaAuth={oktaAuth}>
					<BranchProvider>
						<CartProvider>
							<ErrorBoundaryComp>
								<PayPalScriptProvider
									options={initialOptions}
									deferLoading={true}
								>
									<PelCampaignCode>
										{element}
										{process.env.NODE_ENV === 'production' && (
											<>
												<Monetate />
												<Speedcurve />
												<Eloqua />
												<GoogleTagManager />
												<CheqTag />
												<QuiqChat />
												<Script
													src='//cdn.bc0a.com/autopilot/f00000000230629/autopilot_sdk.js'
													key='autopilot'
												/>
											</>
										)}
									</PelCampaignCode>
								</PayPalScriptProvider>
							</ErrorBoundaryComp>
						</CartProvider>
					</BranchProvider>
				</OktaProvider>
			</FlagsmithProvider>
		</Provider>
	);
};
