import React from 'react';
import { Script } from 'gatsby';

export const Monetate = () => (
	<Script
		id='monetate-script'
		key='monetate'
	>
		{`
				var monetateT = new Date().getTime(); var pellamon = {};
				(function() {
					var p = document.location.protocol;
					if (p == "http:" || p == "https:") {
						var m = document.createElement('script');
						m.type = 'text/javascript';
						m.async = true;
						m.src = "//se.monetate.net/js/2/a-446afbad/p/pella.com/custom.js";
						var s = document.getElementsByTagName('script')[0];
						s.parentNode.insertBefore(m, s);
					}
				})();
			`}
	</Script>
);
