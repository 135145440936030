import * as Sentry from '@sentry/gatsby';

Sentry.init({
	dsn: process.env.GATSBY_SENTRY_DSN,
	// Optional settings, see https://docs.sentry.io/clients/node/config/#optional-settings
	environment: process.env.GATSBY_CONTENTSTACK_ENVIRONMENT,
	enabled: (() => ['production', 'staging'].indexOf(process.env.GATSBY_CONTENTSTACK_ENVIRONMENT) !== -1)(),
	// debug: true,
	autoSessionTracking: true,
	sampleRate: 1.0,
	attachStacktrace: true,

	// Sentry Performance Monitoring
	// https://github.com/getsentry/sentry-javascript/tree/master/packages/gatsby#sentry-performance
	tracesSampleRate: 0.25,
	// tracesSampler: samplingContext => {
	// 	// Examine provided context data (along with anything in the global namespace) to decide the sample rate
	// 	// for this transaction.
	// 	// Can return 0 to drop the transaction entirely.
	//     if ("...") {
	// 		return 0.5; // These are important - take a big sample
	// 	}
	// 	else if ("...") {
	// 		return 0.01; // These are less important or happen much more frequently - only take 1% of them
	// 	}
	// 	else if ("...") {
	// 		return 0; // These aren't something worth tracking - drop all transactions like this
	// 	}
	// 	else {
	// 		return 0.1; // Default sample rate
	// 	}
	// },

	// Session Replay
	replaysSessionSampleRate: 0,
	// If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
	replaysOnErrorSampleRate: process.env.GATSBY_CONTENTSTACK_ENVIRONMENT === ('production' || 'staging') ? 0.8 : 0,

	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			blockAllMedia: false,
			maskAllText: false,
			maskAllInputs: true,
		}),
		// Sentry.captureConsoleIntegration(),
		Sentry.thirdPartyErrorFilterIntegration({
			// Specify the application keys that you specified in the Sentry bundler plugin
			filterKeys: ['pella-dot-com'],

			// Defines how to handle errors that contain third party stack frames.
			// Possible values are:
			// - 'drop-error-if-contains-third-party-frames'
			// - 'drop-error-if-exclusively-contains-third-party-frames'
			// - 'apply-tag-if-contains-third-party-frames'
			// - 'apply-tag-if-exclusively-contains-third-party-frames'
			behaviour: 'drop-error-if-contains-third-party-frames',
		}),
	],

	browserTracingOptions: {},
	ignoreErrors: [
		// Safari extensions
		'safari-extension://',
		// Chrome extensions
		'chrome-extension://',
		// Firefox extensions
		'moz-extension://',
		// Microsoft Edge extensions
		'ms-browser-extension://',
		// Random plugins/extensions
		'top.GLOBALS',
		// Facebook borked
		'fb_xd_fragment',
		//Adsense
		'pagead',
		// Safelinks scanning
		'Non-Error promise rejection captured',
		// Issue from Sentry
		'TypeError: Failed to fetch',
		'TypeError: NetworkError when attempting to fetch resource.',
		'StubbornFetchError',
		'ttd_dom_ready',
		'No projects were found for this user',
		'cntrUpTag is not defined',
		'fbq',
		'WaitUntilCustomerGUIDIsRetrieved',
	],
	denyUrls: [
		// Google Adsense
		/pagead\/js/i,
		// Facebook flakiness
		/graph\.facebook\.com/i,
		// Facebook blocked
		/connect\.facebook\.net\/en_US\/all\.js/i,
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
		// Issues from Sentry
		/scripttemplates\//i,
		/gatsby-script\//i,
		/app\/webchat\/bridge/i,
		/react-dom\/cjs\/react-dom.production/i,
		/tag\/4-latest\/unified-tag/i,
		/ConversationStarter\/IFrameStorage\/Main/i,
		/pella\/.cache\/loader/i,
		/pella\/webpack\/bootstrap/i,
		/assets\/up/i,
		/sdk\/js/i,
	],

	beforeSend(event) {
		// Check for hydration errors
		const hydrationErrors = ['Hydration failed because the initial UI does not match what was rendered on the server.', 'The server could not finish this Suspense boundary, likely due to an error during server rendering. Switched to client rendering.', 'There was an error while hydrating this Suspense boundary. Switched to client rendering.', 'There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.', 'Text content does not match server-rendered HTML.', 'https://reactjs.org/docs/error-decoder.html?invariant={418,419,422,423,425}', 'Minified React error #418', 'Minified React error #419', 'Minified React error #422', 'Minified React error #423', 'Minified React error #425'];
		if (event.exception?.values?.[0]?.value) {
			if (hydrationErrors.some((error) => event.exception.values[0].value.includes(error))) {
				return null;
			}
		}

		// Check for user agent matches
		const userAgents = ['Silktide', 'HeadlessChrome', 'SpeedCurve'];
		if (userAgents.some((agent) => window.navigator.userAgent.includes(agent))) {
			return null;
		}

		// Check for stack trace patterns
		const stackPatterns = [/onCheqResponse/g, /pinimg.com/g];
		const frames = event.exception?.values?.[0]?.stacktrace?.frames || [];
		if (frames.some((frame) => stackPatterns.some((pattern) => pattern.test(frame.filename)))) {
			return null;
		}

		// If no matches are found, return the event
		return event;
	},
});
