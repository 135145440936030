import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ className }) => (
	<svg
		className={className || ''}
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 237.79 237.79'
	>
		<title>Pella</title>
		<path
			fill='#ffde00'
			d='m117.94,237.79c65.66.53,119.32-52.28,119.85-117.93C238.32,54.19,185.52.53,119.86,0,54.19-.52.53,52.28,0,117.94c-.53,65.66,52.27,119.32,117.94,119.85Z'
		/>
		<path
			fill='#231f20'
			d='m68.15,85.81c2.18-2.72,5.05-1.96,4.06.79-4.39,12.22-22.82,65.32-43.24,80.57-3.24,2.42-7.05,3.34-5.31-3.17,2.94-11.02,30.12-60.23,44.49-78.19Z'
		/>
		<path
			fill='#231f20'
			d='m19.26,99.04c6.19-8.37,16.46-16.58,31.63-24.64,15.35-8.16,28.62-11,35.39-11.14,21.54-.45,25.85,12.96,11.36,29.97-11.69,12.36-32.16,25.06-52.58,31.88,0,0-2.89-1.68-4.26-2.52-1.38-.84-1.1-1.66.43-2.34,18.75-8.34,32.63-16.16,42.07-23.69,10.13-8.07,15-15.46,16.58-19.99,1.23-3.55.7-5.48-3.88-5.23-5.15.27-13.91,2.66-21.15,5.55-16.03,6.39-34.36,16.49-40.58,21.54-2.42,1.97-1.97,3.2-.39,3.12,1.8-.09,2.08.4.95,1.57-1.8,1.88-7.77,4.86-12.23,5.55-7.06,1.11-7.02-4.65-3.34-9.64Z'
		/>
		<path
			fill='#231f20'
			d='m177.32,112.07c-6.05,6.4-13.81,11.61-19.16,14.36-9.4,4.84-14.69,4.35-13.54-9.36.81-9.8,20.06-49.7,47.33-61.72,5.83-2.57,10.7-1.27,7.35,5.35-9.07,17.89-27.74,36.64-44.77,50.5-9.71,7.89-18.13,13.95-25.1,17.86-10.03,5.63-16.36,4.05-15.29-9.74.73-9.36,17.72-51.9,48.03-66.71,6.05-2.96,11.03-1.86,7.55,5.17-17.96,36.31-69.78,76.89-76.6,82.22-4.41,3.45-8.25,5.28-11.35,5.85-6.66,1.39-9.79-3.74-8.16-9.18,1.35-4.75,6.08-9,13.73-15.58-5.34-2.1-5.1-5.34-1.36-9.92,2.94-3.6,14.12-12.81,22.06-14.6,4.43-1,6.63-.02,5.69,3.91-.68,2.82-1.99,4.57-3.98,6.6-1.53,1.55-2.24,1.11-2.27-.5-.02-.83-.6-1.28-2.62-.71-3.78,1.07-8.55,5.5-9.44,6.41-2.6,2.7-1.41,4.06,5.09,1.7,2.82-1.03,4.24.62,2.05,2.36-3.27,2.6-10.97,10-12.66,11.77-5.43,5.68-2.68,7.16,2.73,3.89,17.55-10.61,33.62-26.37,45.59-38.98,13.53-14.24,24.23-30.89,25.25-32.52,1.68-2.65.72-3.08-1.28-1.34-10.15,8.84-29.15,34.44-33.21,44.23-5.44,13.12-6.5,21.46,1.76,18.08,3.67-1.5,10.81-5.85,27.39-18.76,3.97-3.1,7.36-6.87,10.54-10.02,13.22-13.08,24.94-29.26,25.96-30.77,1.66-2.46.49-2.74-1.28-1.43-10.89,8.04-27.53,29.61-34.66,42.41-6.77,12.17-6.54,21.37,1.2,16.81,2.64-1.56,7.14-3.73,17.33-13.7l.09,6.08Z'
		/>
		<path
			fill='#231f20'
			d='m211.31,75.9c-20.03,1.34-38.34,30.3-40.76,39.78-3.47,13.54,7.5,9.68,11.02,7.89,9.61-4.91,28.58-22.38,31.93-27.78,3.59-5.8-.46-7.11-6.41-3.29-8.17,5.25-13.5,19.49-14.17,23.58-2.05,12.54,6.48,12.93,13.58,8.63,5.56-3.38,13.11-12.71,15.88-17.9,1.69-3.16.81-4.21-1.87-1.73-2.58,2.39-7.63,6.8-11.49,8.77-6.31,3.23-9.4.19-9.57-2.67-.42-7.26,4.28-13.1,6.27-14.58,1.49-1.12,2.24-.5,1.16,1.14-1.66,2.52-14.31,13.21-17.91,15.24-7.15,4.05-9.98,3.3-6.03-3.64,3.31-5.82,12.86-18.2,21.98-22.91,1.8-.93,5.31-1.73,5.26-.35-.07,1.75.51,2.22,1.69,1.63,1.88-.94,5.22-3.94,6.53-6.84.88-1.94,2.33-5.6-7.09-4.97Z'
		/>
		<path
			fill='#231f20'
			d='m218.32,122.55c.02-2.27,1.74-3.97,3.94-3.95s3.87,1.74,3.85,4.01-1.74,4-3.91,3.98-3.89-1.73-3.87-4.04Zm3.86,4.84c2.61.02,4.87-1.99,4.89-4.77s-2.2-4.8-4.81-4.82-4.89,1.99-4.91,4.74,2.2,4.82,4.83,4.85Zm-.98-4.43h.97s1.44,2.42,1.44,2.42h.94s-1.55-2.44-1.55-2.44c.81-.1,1.44-.52,1.45-1.51,0-1.09-.63-1.58-1.94-1.59l-2.11-.02-.04,5.53h.84s.02-2.39.02-2.39Zm0-.71v-1.71s1.16,0,1.16,0c.58,0,1.2.13,1.2.82,0,.85-.64.89-1.35.89h-1Z'
		/>
	</svg>
);

Logo.propTypes = {
	className: PropTypes.string,
};

export default Logo;
