import { RTA_LOG } from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
	switch (action.type) {
		case RTA_LOG:
			return action.payload;
		default:
			return state;
	}
}
