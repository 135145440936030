import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { getSessionStorage, setSessionStorage } from './utilities/functions';

export const setupStore = (preloadedState) => {
	const middleware = [thunk];
	const persistedState = preloadedState || getSessionStorage('reduxState') || {};

	const store = createStore(rootReducer, persistedState, compose(applyMiddleware(...middleware)));

	store.subscribe(() => {
		setSessionStorage('reduxState', store.getState());
	});

	return store;
};
