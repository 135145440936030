import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

const OktaContext = createContext({});

export default OktaContext;

export const OktaProvider = ({ oktaAuth, children }) => {
	const [authState, setAuthState] = useState(null);
	const [profile, setProfile] = useState({
		isPro: false,
	});
	const [redirect, setRedirect] = useState(null);

	const isLogged = authState?.accessToken?.accessToken && profile.firstName ? true : false;
	const oktaInitialized = authState ? true : false;

	useEffect(() => {
		if (redirect) {
			setRedirect(null);

			navigate(redirect);
		}
	}, [redirect]);

	return (
		<OktaContext.Provider
			value={{
				oktaAuth,
				authState,
				setAuthState,
				isLogged,
				oktaInitialized,
				profile,
				setProfile,
				redirect,
				setRedirect,
			}}
		>
			{children}
		</OktaContext.Provider>
	);
};

OktaProvider.propTypes = {
	children: PropTypes.node,
	oktaAuth: PropTypes.object,
};
